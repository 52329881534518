:root {
  --dark-black: #000000;
  --transparent-dark-black: rgba(0, 0, 0, 0.9);
  --black: #333333;
  --grey-1: #616566;
  --grey-2: #cacbcb;
  --white: #ffffff;
  --orange: #e87909;
  --warm-orange: #d35400;
  --bold-orange: #e67e22;
  --yellow: #e8d509;
  --bright-yellow: #f1c40f;
  --red: #e83609;
  --crimson-red: #dc143c;
  --vibrant-blue: #3498db;
  --lively-green: #2ecc71;
  --transparent-dark-indigo: rgba(17, 1, 37, .9);
}

.light-mode {
  --bg-color: var(--white);
  --font-color: var(--black);
  --link-color: var(--orange);
  --link-hover-color: var(--orange);
  --link-active-color: var(--orange);
  --button-initial-bg-color: transparent;
  --button-initial-border-color: var(--orange);
  --button-initial-font-color: var(--orange);
  --button-hover-bg-color: var(--orange);
  --button-hover-font-color: var(--white);
  --button-active-bg-color: var(--warm-orange);
  --button-active-font-color: var(--white);
  --button-disabled-bg-color: transparent;
  --button-disabled-border-color: var(--grey-1);
  --button-disabled-font-color: var(--grey-1);
  --button-secondary-bg-color: var(--grey-1);
  --button-secondary-font-color: var(--white);
  --notification-text-color: var(--black);
  --notification-severity-info-bg-color: var(--grey-2);
  --notification-severity-warning-bg-color: var(--yellow);
  --notification-severity-error-bg-color: var(--red);
  --spinner-color: var(--orange);
  --loading-state-bg-color: var(--white);
  --logo-font-color: var(--black);
  --landing-welcome-font-color: var(--white);
  --scrollbars-color: var(--black);
  --scrollbars-color-hover: var(--orange);
  --shadow-color: var(--grey-1);
  --content-box-open-figure-background: var(--white);
  --main-menu-option-font-color: var(--black);
  --dialog-bg-color: rgba(#ffffff 0.5);
  --dialog-border-color: var(--grey-2);
  --content-box-bg-color: rgba(#ffffff 0.6);
}

.dark-mode {
  --bg-color: var(--white);
  --font-color: var(--white);
  --link-color: var(--orange);
  --link-hover-color: var(--orange);
  --link-active-color: var(--orange);
  --button-initial-bg-color: transparent;
  --button-initial-border-color: var(--orange);
  --button-initial-font-color: var(--orange);
  --button-hover-bg-color: var(--orange);
  --button-hover-font-color: var(--white);
  --button-active-bg-color: var(--warm-orange);
  --button-active-font-color: var(--white);
  --button-disabled-bg-color: transparent;
  --button-disabled-border-color: var(--grey-1);
  --button-disabled-font-color: var(--grey-1);
  --button-secondary-bg-color: var(--grey-1);
  --button-secondary-font-color: var(--white);
  --notification-text-color: var(--black);
  --notification-severity-info-bg-color: var(--grey-2);
  --notification-severity-warning-bg-color: var(--yellow);
  --notification-severity-error-bg-color: var(--red);
  --spinner-color: var(--orange);
  --loading-state-bg-color: var(--white);
  --logo-font-color: var(--white);
  --landing-welcome-font-color: var(--white);
  --scrollbars-color: var(--black);
  --scrollbars-color-hover: var(--orange);
  --shadow-color: var(--grey-1);
  --content-box-open-figure-background: var(--white);
  --main-menu-option-font-color: var(--black);
  --dialog-bg-color: rgba(#ffffff 0.5);
  --dialog-border-color: var(--grey-2);
  --content-box-bg-color: rgba(#ffffff 0.6);
}
