h1 {
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0.01rem;
}

h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

h4 {
  font-weight: 600;
  font-size: 1.28rem;
  line-height: 1.6rem;
  margin: 0;
}

h5 {
  font-weight: 600;
  font-size: 1.05rem;
  line-height: 1.3rem;
  margin: 0;
}

.big-title {
  font-weight: 900;
  font-size: 6.85rem;
  line-height: 7rem;
  margin: 0;
}

/*
   Phones
  */
@media only screen and (max-width: 900px) {
  .big-title {
    font-size: 3.5rem;
    line-height: 3.6rem;
  }
}
