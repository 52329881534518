.gaf-article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gaf-article > .edition-date {
  text-align: right;
}

.gaf-article > h1 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.gaf-article > h1 > figure > svg {
  width: 30px !important;
}

.gaf-article figure {
  width: 100%;
}

.gaf-article figure > img {
  width: 100%;
}

.gaf-article a {
  display: inline !important;
  width: max-content !important;
}

.gaf-article .columns-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.gaf-article .columns-2 > div {
  max-width: calc(35dvw - 1rem);
  width: 100%;
}

/*
   Phones
  */

@media only screen and (max-width: 900px) {
  .gaf-article > .edition-date {
    text-align: left;
  }

  .gaf-article .columns-2 > div {
    max-width: none;
  }
}
