@import url(style/main.css);

main > #welcome {
  align-content: space-around;
  background-attachment: fixed;
  background-image: url('https://cdn-gonzaloarenasf.web.app/commons/gonzaloarenasf-welcome-background-desktop.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  height: 100dvh;
  justify-content: center;
  margin-bottom: 1rem;
}

main > #welcome > .content {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  padding: 6rem 2rem;
  width: 100vw;
  width: 100dvw;
}

main > #welcome > .content > figure {
  align-items: center;
  height: 170px;
  justify-content: center;
  width: 420px;
}

main > #welcome > .content > figure.logo-desktop {
  display: flex;
}

main > #welcome > .content > figure.logo-mobile {
  display: none;
}

main > #welcome > .content .info {
  margin-top: 1rem;
  text-align: center;
  width: 420px;
}

main > #welcome > .content .info .areas {
  height: 2.3rem;
  overflow: hidden;
}

main > #welcome > .content .info .areas h2 {
  animation: areasTranslation 15s infinite ease;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2rem;
  margin: 0.8rem;
  position: relative;
  vertical-align: bottom;
}

@keyframes areasTranslation {
  0% {
    transform: translateY(-40%);
  }
  15% {
    transform: translateY(-180%);
  }
  35% {
    transform: translateY(-320%);
  }
  55% {
    transform: translateY(-460%);
  }
  75% {
    transform: translateY(-610%);
  }
  85% {
    transform: translateY(-740%);
  }
  100% {
    transform: translateY(-40%);
  }
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
}

main > #welcome > .go-down {
  animation: bounce 0.8s ease-in-out infinite;
  bottom: 5rem;
  position: absolute;
}

main > #about,
main > #jobs,
main > #studies,
main > #highlights {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100dvh;
}

main > #about {
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

main > #about > article {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

main > #about > article > figure > img {
  aspect-ratio: 1 / 1;
  border-radius: 1rem;
  max-width: 400px;
  width: 100%;
}

main > #about > article > div {
  max-width: 650px;
  min-width: 250px;
}

main > #about > article > div > nav {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: end;
}

main > #jobs,
main > #studies,
main > #highlights {
  max-width: 1200px;
  margin: 0 auto;
  width: 90dvw;
}

main > #jobs > article > nav,
main > #studies > article > nav,
main > #highlights > article > nav {
  column-gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

main > #jobs,
main > #studies {
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

main > #jobs > * {
  width: 100%;
}

main > #highlights > article :nth-child(3) {
  justify-content: start;
  margin: 1rem 0;
}

main > #highlights > article :nth-child(3) > a {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  width: 140px;
}

main > #highlights > article :nth-child(3) > a svg {
  width: 30px;
}

/*
 Tablet
*/
@media only screen and (max-width: 1200px) and (orientation: portrait) {
  main > #welcome {
    background-attachment: scroll;
    background-image: url('https://cdn-gonzaloarenasf.web.app/commons/gonzaloarenasf-welcome-background-mobile.jpg');
  }

  main > #welcome > .content {
    align-items: center;
    justify-content: end;
    padding-bottom: 17rem;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
  main > #welcome {
    background-attachment: scroll;
  }
}

/*
   Phones
  */

@media only screen and (max-width: 900px) {
  main > #welcome > .content > figure.logo-desktop {
    display: none;
  }

  main > #welcome > .content > figure.logo-mobile {
    display: flex;
    height: 100px;
    transform: scale(.8);
    width: 300px;
  }

  main > #welcome > .content .info {
    margin-left: 0;
    width: 100%;
  }

  main > #welcome > .go-down {
    bottom: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  main > #about {
    margin-bottom: 3rem;
  }

  main > #about > article > figure {
    width: 90dvw;
  }

  main > #about > article > div {
    width: 90dvw;
  }

  main > #jobs {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  main > #welcome {
    background-attachment: scroll;
    background-image: url('https://cdn-gonzaloarenasf.web.app/commons/gonzaloarenasf-welcome-background-mobile.jpg');
  }

  main > #welcome > .content {
    align-items: center;
    justify-content: space-between;
    padding: 2.3rem 1rem 8rem 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  main > #welcome {
    background-attachment: scroll;
    justify-content: start;
  }

  main > #welcome > .content {
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    padding: 0 2rem 0 4rem;
    text-align: center;
    width: max-content
  }
}
