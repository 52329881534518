button,
a.as-btn {
  background-color: var(--button-initial-bg-color);
  border: 1px solid var(--button-initial-border-color);
  border-radius: 6px;
  color: var(--button-initial-font-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;  /* Safari */
  -ms-user-select: none;      /* IE 10+ and Edge */
  user-select: none;          /* Standard syntax */
  width: max-content;
}

button.btn-secondary,
a.as-btn.btn-secondary {
  background-color: var(--button-secondary-bg-color);
}

button.btn-icon,
a.as-btn.btn-icon {
  border-radius: 100%;
  height: 2rem;
  padding: 0;
  width: 2rem;
}

button:hover,
a.as-btn:hover {
  background-color: var(--button-hover-bg-color);
  color: var(--button-hover-font-color) !important;
}

button:active,
a.as-btn:active {
  background-color: var(--button-active-bg-color);
}

button:disabled,
a.as-btn:disabled {
  background-color: var(--button-disabled-bg-color);
  border: 1px solid var(--button-disabled-border-color);
  color: var(--button-disabled-font-color) !important;
  cursor: no-drop;
}

button.btn-2x,
a.as-btn.btn-2x {
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
}

button.btn-3x,
a.as-btn.btn-3x {
  border-radius: 10px;
  padding: 1.125rem 3rem;
}
